import React from "react";
import gas from "../Imagens/gas2.jpg";
import w1 from "../Imagens/w1.jpg"
import posto from "../Imagens/posto2.jpg"
import envio from "../Imagens/giphy.gif"
import cliente_logo from "../Imagens/clientes.png"
import posto_logo from "../Imagens/posto_logo.png"
import moz_logo from "../Imagens/mozambique.png"
import envio2 from '../Imagens/seta3.gif'
import envio3 from '../Imagens/seta4.gif'
import envio4 from '../Imagens/seta5.gif'
import {FormattedMessage} from "react-intl";

export default function NossaRede2(){
    return(
        <div className=''>
        <div className="relative cell:w-auto cell:h-74 Superdesktop:h-[30rem]">
        <div className="absolute mt-2  font-TCCC text-3xl Superdesktop:text-8xl desktop:text-5xl leading-8 font-extrabold tracking-tight text-center w-full pt-32 text-gray-100">
        <FormattedMessage id="nossarede.titulo"/>
                
            </div>
            <img
            className="desktop:w-full Superdesktop:h-[30rem] Superdesktop:w-full desktop:h-96 cell:h-[16rem]"
            src={gas}
            />
        
        </div>

            <div className="desktop:flex Superdesktop:flex   cell:p-0  ">
                <div className=" bg-gray-100 Superdesktop:p-10 desktop:p-10   Superdesktop:text-2xl  cell:w-auto w-7/12">
                <div className="bg-gray-200 m-1 mt-3 w-auto cell:text-2xl desktop:w-96 Superdesktop:w-[34rem] Superdesktop:h-[12rem] Superdesktop:text-9xl desktop:text-4xl text-black">
                  
                  <div className="flex pt-10 ml-7  w-16 Superdesktop:w-28 Superdesktop:text-5xl ">
                  <img
                  src={cliente_logo}
                  />
              <p className="pt-2 pl-5 pr-0 Superdesktop:pt-8 flex">15.274 <span className="pl-5"> </span> </p>
              <img
                        src={envio2}
                        />
                  </div>
               

              <div>
              <p className="text-gray-900 desktop:text-lg desktop:-mt-3  Superdesktop:text-3xl w-auto font-['TCCC']  Superdesktop:pl-40 text-xl pl-28"><FormattedMessage id="nossarede.name1"/></p> 
              </div>
             </div>
            
             <div className="bg-gray-200 m-1 mt-3 Superdesktop:text-6xl desktop:text-4xl text-black">
            
             <div className="flex pt-10 ml-7  w-16 Superdesktop:w-28 Superdesktop:text-5xl">
                  <img
                  src={posto_logo}
                  />
              <p className="pt-2 pl-5 pr-0 flex Superdesktop:pt-8">22 <span className="pl-5"> </span> </p>
              <img
                        src={envio3}
                        />
                
                  </div>
         

                  <div>
              <p className="text-gray-900 desktop:text-lg  Superdesktop:text-3xl w-auto font-['TCCC'] desktop:-mt-3 Superdesktop:pl-40 text-xl pl-28"><FormattedMessage id="nossarede.name2"/></p> 
              </div>
       </div>   
       <div className="bg-gray-200 m-1 mt-3 Superdesktop:text-6xl desktop:text-4xl text-black">
            
       <div className="flex pt-10 ml-7  w-16 Superdesktop:w-28 Superdesktop:text-5xl">
                  <img
                  src={moz_logo}
                  />
              <p className="pt-2 pl-5 pr-0 flex Superdesktop:pt-8">7<span className="pl-5"> </span> </p>
              <img
                        src={envio2}
                        />
                  
                  </div>
         

                  <div>
              <p className="text-gray-900 desktop:text-lg  Superdesktop:text-3xl w-auto font-['TCCC'] desktop:-mt-3 desktop:mb-3  Superdesktop:pl-40 text-xl pl-28"><FormattedMessage id="nossarede.name3"/></p> 
              </div>
       </div>
    
             
          </div>
          <div className="mt-12 ml-5 mr-4 cell:w-auto w-auto">
          <img
   
          src={w1}
          />
          </div>
    
          
         
      </div>

    


      <div className="flex">
            <div className="absolute  desktop:w-full p-16 Superdesktop:w-full">
                    

                </div>
                <div className=" relative p-5 pt-26  italic cell:text-2xl text-2xl w-auto">
                    <div className="pt-10 pl-20 desktop:text-4xl cell:text-lg text-gray-800 Superdesktop:text-6xl text-6xl text-gray-100">
                    <FormattedMessage id="nossarede.name4"/><br></br><FormattedMessage id="nossarede.name5"/>
                    <br></br>
                    <br/>
                   
                   </div>
                   
                </div>
                
            </div>
            <div className="flex"></div>

            <div className=" text-2xl">
               
            </div>
            
        </div>
    );
}