import React from "react";


export default function TruckStop(){
    return(
        <div className="bg-zinc-800 grid grid-cols-2 gap-2 Superdesktop:text-8xl desktop:text-7xl laptop:text-6xl bg-cover  font-extrabold text-orange-400 font-TCCC w-96 bg-zinc-800  laptop:p-24 Superdesktop:p-52 desktop:p-28 Superdesktop:p-46 cell:p-20 cell:w-full cell:text-2xl  w-screen ">

                  <div className="text-right">Truck</div>
                  <div className="text-gray-400">Stop</div>
        </div>
    );
}