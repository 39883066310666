import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import AppEng from './AppEng';
import Navbar from "./Components/Navbar";
import PrimeiraP  from './Components/PrimeiraP';
import Vantagens from './Components/Vantagens';
import Footer from './Components/Footer';
import Contacte from './Components/Contacte';
import Download from './Components/Download';
import { AppBar, Slider } from '@mui/material';
import Questoes from './Components/Questoes';
import QuestoesEng from './Components/QuestoesEng';
import Swiper from 'swiper';
import Swipp from './Components/Swipp';
import NavBar from './Components/Navbar';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Select from 'react-select';

import NavBarEng from './Components/NavbarEng';
import DownloadEng from './Components/DownloadEng';
import FooterEng from './Components/FooterEng';
import VantagensEng from './Components/VantanesEng';
import Login from './Components/Login';
import PrimeiraPEng from './Components/PrimeiraPEng';

import ImgTest from './Components/ImgTest';
import Nav from './Components/Nav'
import Logout from './Components/Logout';
import Fogot_pass from './Components/Fogot_pass';
import FirstSection from './Components/GoFuelCard';
import GoFuelFleet from './Components/GoFuelFleet';
import Inicio from './Components/Inicio';

import QuemSomos from './Components/QuemSomos';
import QuemSomosEng from './Components/QuemSomosEng';
import InicioEng from "./Components/InicioEng";
import GoFuelFleetEng from './Components/GoFuelFleetEng';
import SwippEng from './Components/SwippEng';
import NossaRede from './Components/NossaRede';
import Campanhas from './Components/Campanhas';
import NossaRede2 from './Components/NossaRede2';
import GoFuelCard from './Components/GoFuelCard';
import GoFuelCardEng from './Components/GoFuelCardEng';
import CampanhasEng from './Components/CampanhasEng';
import NossaRedeEng from './Components/NossaRedeEng';
import TruckStop from './Components/TruckStop';
import AppTruckStop from './AppTruckStop';
import AppTruckStopEng from './AppTruckStopEng';

import {IntlProvider} from "react-intl";
import { useIntl } from 'react-intl';

import { Domain } from 'domain';
import { domainToUnicode } from 'url';

import Portugues from "./Languages/pt-POR.json";
import English from "./Languages/en-Us.json";
import {useState} from 'react'


import { Fragment } from 'react'
import { Popover, Transition } from '@headlessui/react'
import { MenuIcon, XIcon } from '@heroicons/react/outline'
import Logo from "./Imagens/logo3.png"
import Logo2 from "./Imagens/logo.png"
import World from "./Imagens/world.png"



import {FormattedMessage} from "react-intl";

import { LanguageService } from 'typescript'
import { Link } from 'react-router-dom'
import { getValue } from '@testing-library/user-event/dist/utils';


const local = navigator.language;
let l=navigator.language;

const url=window.location.pathname;

let lang=English;
const idiomas = [
  { value: "Portugues" , label: "Português" },
  { value: "English", label: "English" }


];

const navigation = [
    { name: "nav.home", href: '/' },
  
    { name: 'nav.nossarede', href: '/Nossarede' },
    { name: 'nav.campanha', href: '/Campanha' },
    { name: 'nav.truckstop', href: '/TruckStop' },
  ]


  





function ProviderIntl() {
  const [locale, setLocale] = useState(Portugues);

  function MudarIdioma(selec: any){


    if(selec=="Portugues"){
      setLocale(Portugues)
      
    }else{
      setLocale(English)
    }



  }
   
    return (
       
  <IntlProvider locale={local} messages={locale}>
   

    
  <BrowserRouter>
  <div className="z-10 bg-zinc-800 -top-2  Superdesktop:pt-5 fixed desktop:-h-32 w-full h-10 ">
         
         <div className="   h-10 w-full desktop:h-10 ">
           <div className="  bg-zinc-800 pb-0 cell:pb-16  laptop:w-full desktop:w-full desktop:h-10 laptop:h-8 h-10 pt-2 pl-5 ">
          
   
             <Popover>
               <div className=" cell
               :px-6 ">
                 <nav className=" flex items-center justify-between cell:h-10 desktop:justify-end laptop:justify-end "  aria-label="Global">
                   <div className="flex items-center flex-grow flex-shrink-0 ">
                     <div className="flex items-center justify-between w-full desktop:w-auto tablet:w-auto">
                       <a href="#">
                         <span className="sr-only">Workflow</span>
                         <img
                           alt="Workflow"
                           className="Superdesktop:h-16 mt-5 h-8 w-auto cell
                           :h-5"
                           src={Logo}
                         />
                       </a>
   
                    
                       <div className="flex items-center tablet:hidden ">
                         <Popover.Button className="bg-white rounded-tablet inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                           <span className="sr-only">Open main menu</span>
                           <MenuIcon className="h-6 w-6" aria-hidden="true" />
                         </Popover.Button>
                       </div>
                     </div>
                   </div>
             
                  
                   <div className="hidden flex tablet:block tablet:ml-2  font-medium Superdesktop:text-3xl Superdesktop:pt-15 mt-5 tablet:pr-4 tablet:space-x-8 text-gray-200">
                  
                  
                   <Link className='hover:text-orange-400' to={"gofuel/paginainicial/"}><FormattedMessage id={'nav.home'} /></Link>
                   <Link className='hover:text-orange-400' to={"gofuel/paginainicial/NossaRede"}><FormattedMessage id={'nav.nossarede'} /></Link>
                   <Link className='hover:text-orange-400' to={"gofuel/paginainicial/Campanha"}><FormattedMessage id={'nav.campanha'} /></Link>
                   <Link className='hover:text-orange-400' to={"gofuel/paginainicial/TruckStop"}><FormattedMessage id={'nav.truckstop'} /></Link>
             
                    
                     {/**
                     {navigation.map((item) => (
                       <a key={item.name} href={item.href} className="Superdesktop:text-2xl  font-medium text-gray-200 hover:text-orange-400">
                         {item.name}
                       </a>
                     ))}
                      */}
   
                  
                  
   
                    
                   </div>
                   <div className='flex hidden tablet:block pt-5 tablet:space-x-8 pl-5 pr-5'>
                         <div className=" inline-flex items-center justify-center text-gray-400  focus:outline-none focus:ring-2 focus:ring-inset focus:ring-orange-400">
                           <span className="sr-only">Open main menu</span>
                         
                      <Select
                  className="select_api"
                  defaultInputValue='Português'
                  name="color"
                  
                 
                  options={idiomas}
          
                  onChange={(e) => MudarIdioma(e?.value)}

               / >
 

                </div>

                         <Popover.Button className=" inline-flex items-center justify-center text-gray-400  focus:outline-none focus:ring-2 focus:ring-inset focus:ring-orange-400">
                           <span className="sr-only">Open main menu</span>
                     Login
                         </Popover.Button>
                  
                    
                     </div>
                 </nav>
               </div>
               
   
               <Transition
                 as={Fragment}
                 enter="duration-150 ease-out"
                 enterFrom="opacity-0 scale-95"
                 enterTo="opacity-100 scale-100"
                 leave="duration-100 ease-in"
                 leaveFrom="opacity-100 scale-100"
                 leaveTo="opacity-0 scale-95"
               >
                 <Popover.Panel
                   focus
                   className="z-10 top-0  p-2 transition transform origin-top-right tablet:hidden"
                 >
                   <div className=" rounded-desktop shadow-tablet bg-white ring-1 ring-black ring-opacity-5 overflow-hidden">
                     <div className="px-5 pt-4 flex items-center justify-between">
                       <div>
                         <img
                           className="h-8 w-auto"
                           src={Logo2}
                           alt=""
                         />
                       </div>
                       <div className="mr-2">
                         <Popover.Button className="bg-white rounded-tablet p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                           <span className="sr-only">Close main menu</span>
                           <XIcon className="h-6 w-6" aria-hidden="true" />
                         </Popover.Button>
                       </div>
                     </div>
                     <div className="px-2 pt-2 pb-3 ">
                       {navigation.map((item) => (
                         <a
                           key={item.name}
                           href={item.href}
                           className="block px-3 py-2 rounded-tablet text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50"
                         >
   
                                    <FormattedMessage id={item.name+""} />
                         </a>
                       ))}
                     </div>
                 
   
                 <div className='grid grid-cols-3 gap-8 '>
                     
     
                 <button  onClick={() => setLocale(English)}>English</button> 
                 
                    <button onClick={() => setLocale(Portugues)}>Português</button> 
                 
   
                 </div>
                     
   
                 <div className='w-full center grid grid-cols-3 gap-8 '>

                 Log in
                 </div>
   
             
                 <div className='grid grid-cols-3 gap-8 '>
                     <a
                       href="http://www.gofuel-mz.com/gofuel/Empresa/"
                       className="block w-full px-5 py-3 text-center font-medium text-orange-400 bg-gray-50 hover:bg-gray-100"
                     >
                    Empresa
                     </a>
                     <a
                       href="https://gofuel-mz.com/parceiros/"
                       className="block w-full px-5 py-3 text-center font-medium text-orange-400 bg-gray-50 hover:bg-gray-100"
                     >
                    Parceiro
                     </a>
                     </div>
                   </div>
                 </Popover.Panel>
               </Transition>
   
   
   
   
   
            


               <Transition
                 as={Fragment}
                 enter="duration-150 ease-out"
                 enterFrom="opacity-0 scale-95"
                 enterTo="opacity-100 scale-100"
                 leave="duration-100 ease-in"
                 leaveFrom="opacity-100 scale-100"
                 leaveTo="opacity-0 scale-95"
               >
                 <Popover.Panel
                   focus
                   className="z-10 w-full Superdesktop:pl-[90%] align-center pl-[90%] hidden tablet:block top-0 place-content-end   p-2 transition transform origin-top-left "
                  
                 >
                   <div className="w-[100%] h-[80px] place-content-end align-end shadow-tablet bg-white  overflow-hidden">
                   
                   <button className='h-[40px] hover:text-white hover:bg-orange-400 pl-4 w-full pr-14'>
                   <a href='http://www.gofuel-mz.com/gofuel/Empresa'  > Empresarial </a>   
                   </button>
                  
              
                   <button className=' h-[40px] hover:text-white hover:bg-orange-400 pl-4 w-full pr-14'>
                   <a href='https://gofuel-mz.com/parceiros/' className=' pl-4 w-96 hover:bg-orange-400 pr-14 h-96'> Parceiro </a>   
                  {/**  <button className=' w-full h-12'  onClick={() => setLocale(Portugues)}>Parceiro</button>   */  }
                  </button>
                  
                   </div>
                 </Popover.Panel>
               </Transition>
             </Popover>
   
           
           </div>
         </div>
       
       </div>
   
      <Routes>
        
        <Route path="*" element={<><Inicio/><QuemSomos/><GoFuelCard/><GoFuelFleet/><Vantagens/><App/><Questoes/><Download/><Swipp/><Footer/></>} />
     
        <Route path="gofuel/paginainicial/NossaRede" element={<><NossaRede2/></>} />
        <Route path="gofuel/paginainicial/TruckStop" element={<><TruckStop/><AppTruckStop/></>} />
  
      </Routes>
    </BrowserRouter>,
  
    </IntlProvider>
  
    
    );
}

export default ProviderIntl;

