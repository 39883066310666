import React from "react";
import olho from "../Imagens/olho.png";
import cadeado from "../Imagens/cadeado.png";
import { AnnotationIcon, LockClosedIcon,EyeIcon, GlobeAltIcon, CreditCardIcon, ScaleIcon } from '@heroicons/react/outline'
import { FormattedMessage } from 'react-intl'

const features = [
  {
    name: 'vantagens.name1',
    description:
      'vantagens.desc1',
    icon: EyeIcon,
  },
  {
    name: 'vantagens.name2',
    description:
      'vantagens.desc2',
       icon: LockClosedIcon,
  },
  {
    name: 'vantagens.name3',
    description:
      'vantagens.desc3',
      icon: GlobeAltIcon,
  },
  {
    name: 'vantagens.name4',
    description:
      'vantagens.desc4',
      icon: CreditCardIcon,
  },
]



export default function Vantagens(){

    return(
      
      <div className="py-12 bg-white p-5">
      <div className=" mx-auto  tablet:px-6 lg:px-8">
        <div className="text-center">
    
          <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 tablet:text-4xl cell:text-4xl laptop:text-4xl desktop:text-4xl Superdesktop:text-6xl">
          
          <FormattedMessage
                              id={'vantagens.titulo'}
                                />

          </p>
          <p className="mt-4 text-xl text-gray-500 cell:text-sm cell:mt-2 laptop:mx-auto Superdesktop:text-2xl">
          <FormattedMessage
                              id={'vantagens.subtitulo'}
                                />
          </p>
        </div>

        <div className=" p-10 cell:p-0 ">
          <dl className="space-y-10 mt-10  laptop:space-y-0 laptop:grid laptop:grid-cols-2 laptop:gap-x-8 laptop:gap-y-10 Superdesktop:gap-y-8">
            {features.map((feature) => (
              <div key={feature.name} className="relative Superdesktop:p-10">
                <dt>
                  <div className="absolute flex items-center justify-center cell:h-10 cell:w-10 h-12 w-12 rounded-md bg-orange-500 text-white  Superdesktop:h-20 Superdesktop:w-20">
                    <feature.icon className="h-6 w-6 " aria-hidden="true" />
                  </div>
                  <p className="Superdesktop:ml-24  desktop:ml-16 laptop:ml-16 tablet:ml-16 cell:ml-12 cell:text-2xl  text-3xl leading-8 font-extrabold tracking-tight text-gray-900 tablet:text-4xl laptop:text-xl Superdesktop:text-3xl">
                       <FormattedMessage
                              id={feature.name}
                                />
                    </p>
                </dt>
                <dd className="Superdesktop:ml-24  cell:ml-12 Superdesktop:text-2xl mt-2 ml-16 text-base text-gray-500">
                <FormattedMessage
          id={feature.description}
          /></dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>

    );
}