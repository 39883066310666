import React, {useEffect, useState} from 'react';
import { isPropertySignature } from 'typescript';
import './App.css';
import NavBar from './Components/Navbar';
import Map from './Map/';
import {loadMapApi} from "./utils/GoogleMapsUtils";
import { DataGrid, GridToolbar, GridToolbarQuickFilter } from "@mui/x-data-grid";
import { Box } from '@mui/material';
import { setuid } from 'process';



function App() {
    const [scriptLoaded, setScriptLoaded] = useState(false);
    const [distanceInKm, setDistanceInKm] = useState<number>(-1);
    const [dataRequisicao, setDataRequisicao] = useState([]);


    

    useEffect(() => {
        const googleMapScript = loadMapApi();
        googleMapScript.addEventListener('load', function () {
            setScriptLoaded(true);
        });
        fetchApi();
    }, []);

    const renderDistanceSentence = () => {
        return (
            <div className='distance-info'>
                {`Distance between selected marker and home address is ${distanceInKm}km.`}
            </div>
        );
    };

    function Mapa(){
        
    }

    function QuickSearchToolbar() {
        return (
          <Box
            sx={{
              p: 0.5,
              pb: 0,
            }}
          >
            <GridToolbarQuickFilter />
          </Box>
        );
      }

    const [idiom,setIdiom]= useState('Port')
    const [url, setUrl] = useState("https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d7336745.838638866!2d37.87158738002808!3d-18.65895090308131!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1spt-PT!2smz!4v1682676108279!5m2!1spt-PT!2smz")


    function mudarMapa(e:any){

        if(e=="Maputo, Belo Horizonte - Av. De Namaacha"){
            setUrl("https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3585.412759270737!2d32.39911838848786!3d-26.02004749319039!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1ee687f3aeeb7097%3A0xfac2d8905a1919c7!2sPetromoc!5e0!3m2!1spt-BR!2smz!4v1683022225294!5m2!1spt-BR!2smz")
        }
        if(e=="AUTOGAS - EMTPM"){
            setUrl("https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d896.8233505231644!2d32.5756045!3d-25.9584162!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1ee69bdc9d6de63b%3A0x438ae8b526ccfe04!2zQXV0byBHw6Fz!5e0!3m2!1spt-BR!2smz!4v1683022369435!5m2!1spt-BR!2smz")
        }
        if(e=="Nampula - Av. EN1"){
            setUrl("https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d240.74126531747552!2d39.22971593377834!3d-15.111029224495063!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x18c63705d3924473%3A0x3c58ea0eb6d3e82a!2sPUMA!5e0!3m2!1spt-BR!2smz!4v1683022836781!5m2!1spt-BR!2smz")
        }
        if(e=="I2A Energia - Chibuto"){
            setUrl("https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d855.4044274164592!2d33.533221208667406!3d-24.68985776855516!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjTCsDQxJzI0LjEiUyAzM8KwMzInMDQuOSJF!5e0!3m2!1spt-BR!2smz!4v1683022979474!5m2!1spt-BR!2smz")
        }
        if(e=="Total Macia - Av. EN1"){
            setUrl("https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d1755.9285204801572!2d33.07141275541619!3d-25.04214017748628!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjXCsDAyJzMyLjEiUyAzM8KwMDQnMTYuNSJF!5e0!3m2!1spt-BR!2smz!4v1683023239062!5m2!1spt-BR!2smz")
        }
        if(e=="Petromoc Inchope - EN1"){
            setUrl("https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3767.5634728816945!2d33.9199929!3d-19.2142595!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1ed52f0c54b5b3f5%3A0xef032bdee650ed0b!2sPETROMOC!5e0!3m2!1spt-BR!2smz!4v1683031881718!5m2!1spt-BR!2smz")
        }
        if(e=="Cidade de Maputo, Av. Karl Marx"){
            setUrl("https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3586.9427860060155!2d32.571!3d-25.9699167!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjXCsDU4JzExLjciUyAzMsKwMzQnMTUuNiJF!5e0!3m2!1spt-BR!2smz!4v1683031996600!5m2!1spt-BR!2smz")
        }
        if(e=="Petromoc Sasol - Tambara 2"){
            setUrl("https://www.google.com/maps/embed?pb=!1m13!1m8!1m3!1d1291.3921054763384!2d33.47889288826187!3d-19.09578095582317!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTnCsDA1JzQyLjciUyAzM8KwMjgnNDQuMCJF!5e0!3m2!1spt-BR!2smz!4v1683034296960!5m2!1spt-BR!2smz")
        }
        if(e=="Total Intaka"){
            setUrl("https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11421.56633698405!2d32.54995851500913!3d-25.81582823027949!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1ee6927aa80dd0c5%3A0x126a19504e15e1f6!2sEsta%C3%A7%C3%A3o%20de%20Servi%C3%A7o%20TOTAL%20INTAKA!5e0!3m2!1spt-PT!2smz!4v1683105684666!5m2!1spt-PT!2smz")

        }
        if(e=="Manhiça"){
            setUrl("https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3605.4375750865083!2d32.8127277!3d-25.3566473!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1ee727c738d58917%3A0x7aca8f61e3833e6a!2sGol2A!5e0!3m2!1spt-BR!2smz!4v1683105913128!5m2!1spt-BR!2smz")
        }
        if(e=="Total Matola Gare - Estrada Circular de Maputo"){
            setUrl("https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7182.857171974372!2d32.4309681!3d-25.8224191!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1ee68b4bd34097af%3A0xdb7aa0641f5cbce4!2sBombas%20Total!5e0!3m2!1spt-BR!2smz!4v1683106306681!5m2!1spt-BR!2smz")
        }
        if(e=="Maputo, Marracuene - Av. EN1"){
            setUrl("https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3595.365931187707!2d32.6610278!3d-25.692305599999997!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjXCsDQxJzMyLjMiUyAzMsKwMzknMzkuNyJF!5e0!3m2!1spt-BR!2smz!4v1683117728400!5m2!1spt-BR!2smz")
        }
        if(e=="AUTOGAS - MOZAL"){
            setUrl("https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3588.632766636376!2d32.4131121!3d-25.9144402!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1ee6897ac0cbe33b%3A0x3dcd3b69d457c5fa!2sMGC-%20Matola%20Gas%20Company!5e0!3m2!1spt-BR!2smz!4v1683117905247!5m2!1spt-BR!2smz")
        }
        if(e=="Maputo, Marracuene"){
            setUrl("https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3590.9827323313452!2d32.6688189!3d-25.8371143!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1ee69706e844f721%3A0x57f25b04485df099!2sPetromoc%20SASOL%20Muntanhana%20I!5e0!3m2!1spt-BR!2smz!4v1683118106728!5m2!1spt-BR!2smz")
        }
        if(e=="Beira, Inhamizua - Av. EN6"){
            setUrl("https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1877.7879989978328!2d34.8223327!3d-19.7306679!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1f2a685e9aa20683%3A0xed58024ce950602f!2sTotalEnergies!5e0!3m2!1spt-BR!2smz!4v1683118210565!5m2!1spt-BR!2smz")
        }
        if(e=="Maputo,  Ponta do Ouro"){
            setUrl("https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3559.8100237738317!2d32.8206935!3d-26.8459941!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1ee48fc16edd4b83%3A0xceffe2ead2e7e3eb!2sPetromoc%20Sasol%20Ponta%20de%20Ouro!5e0!3m2!1spt-BR!2smz!4v1683119180725!5m2!1spt-BR!2smz")
        }
        if(e=="Maputo, Salamanga EN200"){
            setUrl("https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3570.81882154445!2d32.662!3d-26.4937778!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjbCsDI5JzM3LjYiUyAzMsKwMzknNDMuMiJF!5e0!3m2!1spt-BR!2smz!4v1683119267825!5m2!1spt-BR!2smz")
        }
        if(e=="Tete,  Av. EN6"){
            setUrl("https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3831.3363705322186!2d33.58552207500767!3d-16.20313798449593!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x192639cddddf2393%3A0xfde105a4f06a0268!2sPuma!5e0!3m2!1spt-BR!2smz!4v1683187503849!5m2!1spt-BR!2smz")
        }
        if(e=="I2A Energia - Xai-Xai"){
            setUrl("https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d957.4983268173149!2d33.635072867641966!3d-25.03297618804385!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjXCsDAxJzU4LjIiUyAzM8KwMzgnMDYuNCJF!5e0!3m2!1spt-BR!2smz!4v1683188057798!5m2!1spt-BR!2smz")
        }
        if(e=="Maputo, Av. Moçambique"){
            setUrl("https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d411.3068495624392!2d32.55729253982318!3d-25.92647863865082!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjXCsDU1JzM1LjgiUyAzMsKwMzMnMjYuNCJF!5e0!3m2!1spt-BR!2smz!4v1683188214103!5m2!1spt-BR!2smz")

        }

        console.log(e)

    }

    const nameColumn = [
        
        {
          field: "descricao",
          headerName: "",
          width: 300,
          renderCell: (params: any) => {
            return(
              <div style={{display: "table-column"}} onClick={(e)=> mudarMapa(params.row.descricao
                )}>
                    <p className='text-left'>{params.row.provincia}</p>
                 
           
            <p>         {params.row.descricao}</p>
       
              </div>
            )
          }
        
        },
       
      ]


 


      const fetchApi = () => {
        fetch("https://gofuelmz.com/api/postos", {method:"GET"}) // 'https://jsonplaceholder.typicode.com/users'
        .then(response => {
            return response.json()
        })
        .then(json => {
            setDataRequisicao(json)
        }
            )
        
    
        .catch((err) => {
          console.log(err.message);
        });
      };

       
    return (
        <div className="App p-10 cell:p-0">
            <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
 
            Rede de Postos

            </p>
            <br/>
           
        {/* {scriptLoaded && (
            <Map
              mapType={google.maps.MapTypeId.ROADMAP}
              mapTypeControl={true}
              setDistanceInKm={setDistanceInKm}
            />
        )}
        {distanceInKm > -1 && renderDistanceSentence()} */}
        <div style={{display: "flex", marginLeft: "32px"}}>
                 
        {/* <iframe src={url} width="800" style={{marginLeft: "30px"}} height="450"  loading="lazy"></iframe> */}

        <div style={{display: "flex-start", marginLeft: "10px", alignItems: 'flex-start'}}>


      

        {/* <button onClick={()=>setUrl("https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d230.71326563753257!2d32.600773952414315!3d-25.957421298086555!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1ee69b015c68ccf7%3A0x91ac46d9873a721f!2sAJ%26C!5e0!3m2!1spt-PT!2smz!4v1682678302306!5m2!1spt-PT!2smz")}>GoFuel</button><br/>
        <button onClick={()=>setUrl("https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d335.0363336703703!2d32.400428526752094!3d-26.01982058842722!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1ee687f3aeeb7097%3A0xfac2d8905a1919c7!2sPetromoc!5e0!3m2!1spt-BR!2smz!4v1682678724084!5m2!1spt-BR!2smz")}>Petromoc-Belo Horizonte</button><br/>
        <button onClick={()=>setUrl("https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d224.20596368215703!2d32.57580087098195!3d-25.95835003284591!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1ee69bdc9d6de63b%3A0x438ae8b526ccfe04!2zQXV0byBHw6Fz!5e0!3m2!1spt-BR!2smz!4v1682679304616!5m2!1spt-BR!2smz")}>AUTOGAS-Cidade de Maputo</button>
     */}

         </div>

 
        </div>

        <div className=" p-10 cell:p-0 ">
          <dl className="space-y-10 mt-10  laptop:space-y-0 laptop:grid laptop:grid-cols-2 laptop:gap-x-8 laptop:gap-y-10 Superdesktop:gap-y-8">
       
              <div className='ml-10'>
              <iframe 
            width="150%" 
            height="470" 
            
            scrolling="no" 


            src={url}
            >
 </iframe>
              </div>

              <div  style={{width: "50%", marginLeft: "50%", marginRight: "20px"}}>  <DataGrid
              

className="datagrid"

rows={dataRequisicao}
columns={nameColumn}
autoPageSize={true}



disableRowSelectionOnClick

disableColumnFilter
disableColumnSelector
disableDensitySelector

components={{ Toolbar: QuickSearchToolbar  }}
componentsProps={{
  toolbar: {
    showQuickFilter: true,
    quickFilterProps: { debounceMs: 500 },
  },
}}


/></div>
          
          </dl>
        </div>

     
    </div>
  
    
    );
}

export default App;

