import React, {useEffect, useRef, useState} from 'react';

import Bombas from "../Imagens/bomba_icon2.png"


interface IMap {
    mapType: google.maps.MapTypeId;
    mapTypeControl?: boolean;
    setDistanceInKm: React.Dispatch<React.SetStateAction<number>>;
}

interface IMarker {
    address: string;
    latitude: number;
    longitude: number;
}

type GoogleLatLng = google.maps.LatLng;
type GoogleMap = google.maps.Map;
type GoogleMarker = google.maps.Marker;
type GooglePolyline = google.maps.Polyline;

const Map: React.FC<IMap> = ({ mapType, mapTypeControl = false, setDistanceInKm}) => {

    const ref = useRef<HTMLDivElement>(null);
    const [map, setMap] = useState<GoogleMap>();
    const [marker, setMarker] = useState<IMarker>();
    const [homeMarker, setHomeMarker] = useState<GoogleMarker>();
    const [googleMarkers, setGoogleMarkers] = useState<GoogleMarker[]>([]);
    const [listenerIdArray, setListenerIdArray] = useState<any[]>([]);
    const [LastLineHook, setLastLineHook] = useState<GooglePolyline>();

    const startMap = (): void => {
        if (!map) {
            defaultMapStart();
        } else {
            const homeLocation = new google.maps.LatLng(-23.39531, 30.56875);
          
            setHomeMarker(addHomeMarker(new google.maps.LatLng(-25.96991, 32.570995)));
            setHomeMarker(addHomeMarker(new google.maps.LatLng(-22.02661, 35.161530)));
            setHomeMarker(addHomeMarker(new google.maps.LatLng( -19.21473,33.922348)));


        }
    };
    useEffect(startMap, [map]);

    const defaultMapStart = (): void => {
        const defaultAddress = new google.maps.LatLng(-18.80000, 30.56875);
        initMap(5, defaultAddress);
    };

    const initEventListener = ():void => {
        if (map) {
            google.maps.event.addListener(map, 'click', function(e) {
                coordinateToAddress(e.latLng);
            })
        }
    };
    useEffect(initEventListener, [map]);

    const coordinateToAddress = async (coordinate: GoogleLatLng) => {
        const geocoder = new google.maps.Geocoder();
        await geocoder.geocode({ location: coordinate}, function (results, status) {
            if (status === 'OK') {
                setMarker({
                    address: results[0].formatted_address,
                    latitude: coordinate.lat(),
                    longitude: coordinate.lng()
                })
            }
        });
    };

    useEffect(() => {
        if (marker) {
            addMarker(new google.maps.LatLng(marker.latitude, marker.longitude));
      
        }
    }, [marker]);

    const addMarker = (location: GoogleLatLng): void => {
        const marker:GoogleMarker = new google.maps.Marker({
            position: location,
            map: map,
            icon: getIconAttributes('#000000')
        });

        setGoogleMarkers(googleMarkers => [...googleMarkers, marker]);

        const listenerId = marker.addListener('click', () => {
            const homePos = homeMarker?.getPosition();
            const markerPos = marker.getPosition();
            if (homePos && markerPos) {
                const distanceInMeters = google.maps.geometry.spherical.computeDistanceBetween(homePos, markerPos);
                setDistanceInKm(Math.round(distanceInMeters / 1000));

                if (LastLineHook) {
                    LastLineHook.setMap(null);
                }

                const line = new google.maps.Polyline({
                    path: [
                        { lat: homePos.lat(), lng: homePos.lng()},
                        { lat: markerPos.lat(), lng: markerPos.lng()},
                    ],
                    icons: [
                        {
                            icon: {
                                path: google.maps.SymbolPath.FORWARD_OPEN_ARROW
                            },
                            offset: "100%"
                        }
                    ],
                    map: map,
                });

                setLastLineHook(line);
            }
        });

        setListenerIdArray(listenerIdArray => [...listenerIdArray, listenerId]);
    };

    useEffect(() => {
        listenerIdArray.forEach((listenerId) => {
           google.maps.event.removeListener(listenerId);
        });

        setListenerIdArray([]);
        setGoogleMarkers([]);
        googleMarkers.forEach((googleMarker) => {
            const markerPosition = googleMarker.getPosition();
            if (markerPosition) {
                addMarker(markerPosition);
                                
            }
        });
    }, [LastLineHook]);

    const addHomeMarker = (location: GoogleLatLng): GoogleMarker => {
        const homeMarkerConst:GoogleMarker = new google.maps.Marker({
            position: location,
            map: map,
            icon: {
                url: window.location.origin + '/assets/images/placeholder.png'
            }
        });

        homeMarkerConst.addListener('click', () => {
            map?.panTo(location);
            map?.setZoom(12);
           
        });

        return homeMarkerConst;
    };

    const getIconAttributes = (iconColor: string) => {
        return {
            path: 'M11.0639 15.3003L26.3642 2.47559e-05L41.6646 15.3003L26.3638 51.3639L11.0639 15.3003 M22,17.5a4.5,4.5 0 1,0 9,0a4.5,4.5 0 1,0 -9,0Z',
            fillColor: iconColor,
            fillOpacity: 0.8,
            strokeColor: 'pink',
            strokeWeight: 2,
            anchor: new google.maps.Point(30, 50)
        };
    };

    const initMap = (zoomLevel: number, address: GoogleLatLng): void => {
        if (ref.current) {
            setMap(
                new google.maps.Map(ref.current, {
                    zoom: zoomLevel,
                    center: address,
                    mapTypeControl: mapTypeControl,
                    streetViewControl: false,
                    rotateControl: false,
                    scaleControl: true,
                    fullscreenControl: false,
                    panControl: false,
                    zoomControl: true,
                    gestureHandling: 'cooperative',
                    mapTypeId: mapType,
                    draggableCursor: 'pointer',
                })
            );
        }
    };

    return (
    
    <div className='h-screen'>
          
        <div className='justify-center  text-2xl flex '>
        <div className="lg:text-center">

<p className="mt-2 cell:text-2xl text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl Superdesktop:text-5xl">

         
        
POSTOS TRUCKSTOP
</p>
<p className="mt-4  cell:text-sm text-xl text-gray-500 lg:mx-auto Superdesktop:text-2xl">
A nossa rede de postos TruckStop fornece reabastecimento, estacionamento para que os motoristas possam descansar 
</p>
</div>


<br></br>

<p></p>

</div>
<div className='p-5  '>


</div>

 <div className="map-container h-screen p-10">



<div ref={ref} className="w-full h-3/4"></div>

</div>
        
    </div>
       
    );
};

export default Map;
