//rfce

import React from 'react'
import Image from 'next/image'
//import MobileStoreButton from 'react-mobile-store-button';
//import { GooglePlayStoreIcon, AppStoreIcon } from '../utilities/icons/PlayStoreIcons';
import logo from "../Imagens/fleet.jpg"
import android from "../Imagens/android.png"
import apple from "../Imagens/applestore.png"
import huawei from "../Imagens/huawei.png"
import Logo1 from "../Imagens/logo.png"
import { FormattedMessage } from 'react-intl'

console.log(logo);
const features = [
  {
    name: 'gofuelfleet.name1',
    description:
      'gofuelfleet.desc1',
    icon: "1",
  },
  {
    name: 'gofuelfleet.name2',
    description:
      'gofuelfleet.desc2',
    icon: "2",
  },
  {
    name: 'gofuelfleet.name3',
    description:
      'gofuelfleet.desc3',
    icon: "3",
  },
  {
    name: 'gofuelfleet.name4',
    description:
      'gofuelfleet.desc4',
    icon: "4",
  },
  {
    name: 'gofuelfleet.name5',
    description:
      'gofuelfleet.desc5',
    icon: "5",
  },
  {
    name: 'gofuelfleet.name6',
    description:
      'gofuelfleet.desc6',
    icon: "6",
  },
]


function GoFuelfleet() {
 // const iOSUrl = 'https://apps.apple.com/ai/app/gofuel-tracking-system/id1530324152';
 // const androidUrl = 'https://play.google.com/store/apps/details?id=com.gofuel_ios&hl=en_US&gl=US&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'
  return (
    <div className='grid-cols-2 grid'>

<div className="">
        {/* <div class="flex-none"></div> */}
   
 
  <img
    className="h-56 w-full object-cover cell:h-auto cell:w-auto laptop:w-auto laptop:h-auto desktop:w-auto desktop:h-auto"
    src={logo}
    alt="" 
    //width={900} height={500}

  />
  <p className=" relative text-xl Superdesktop:text-4xl desktop:text-xl text-end desktop:-mt-7 pr-28">
        <FormattedMessage
        id='gofuelfleet.texto2'
        />
        </p>
    {/* <Image
    className="h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full"
    src="/images/gofuel_screenshot.png"
    alt="" 
    //width={900} height={500}
    width={300} height={550}
  /> */}


        </div>


        <div className=" ">
        <div className='flex cell:w-24 desktop:w-40 laptop:w-40 pl-5'>
        <span className="Superdesktop:text-9xl  text-6xl tracking-tight font-extrabold cell:text-3xl laptop:text-6xl block text-gray-900 desktop:inline cell:pl-2"> GoFuel</span>
<span className="Superdesktop:text-9xl text-6xl tracking-tight font-extrabold  cell:text-3xl laptop:text-6xl block text-orange-400 desktop:inline cell:pl-2"> fleet</span>
</div>
<p className='Superdesktop:text-4xl cell:text-lg pt-2 pl-5'>
        <FormattedMessage
        id='gofuelfleet.texto1'
       
        />
      </p>
          <dl className="desktop:space-y-2 Superdesktop:space-y-0 mt-10 Superdesktop:mt-3  ">
            {features.map((feature) => (
              <div key={feature.name} className="relative Superdesktop:ml-5 desktop:ml-5 Superdesktop:p-5 p-0">
                <dt>
                  <div className="absolute flex items-center justify-center cell:h-10 cell:w-8 cell:h-8 h-12 w-12 rounded-full bg-orange-500 text-white Superdesktop:text-2xl  Superdesktop:h-14 Superdesktop:w-14">
                  <p className=''>{feature.icon}</p>
                  </div>
                  <div className=''>
                  <p className="Superdesktop:ml-20 desktop:pt-2 font-TCCC  desktop:ml-12 laptop:ml-16 tablet:ml-16 cell:ml-12   text-3xl leading-8 font-extrabold tracking-tight text-orange-400 tablet:text-4xl laptop:text-xl cell:text-xl  Superdesktop:text-4xl">
          <FormattedMessage
          id={feature.name}
          />

                  </p>
                  </div>
                </dt>
                <dd className="Superdesktop:ml-20  cell:ml-12 Superdesktop:text-3xl mt- ml-16 ">
                <FormattedMessage
          id={feature.description}
          />

                </dd>
              </div>
            ))}
          </dl>
        </div>

    
        
        <br/>
<br/>
    </div>
  )
}

export default GoFuelfleet
