import React from "react";
import logo from "../Imagens/logo.png"
import android from "../Imagens/android.png"
import apple from "../Imagens/applestore.png"
import huawei from "../Imagens/huawei.png"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import { faFacebookF } from "@fortawesome/free-brands-svg-icons"
import { faLinkedin } from "@fortawesome/free-brands-svg-icons"
import { faInstagram } from "@fortawesome/free-brands-svg-icons"
import { faTwitter } from "@fortawesome/free-brands-svg-icons"
import { faEnvelope} from "@fortawesome/free-solid-svg-icons"
import logo2 from "../Imagens/logo2.png"
import ns from "../Imagens/sub.jpg"
import { FormattedMessage } from 'react-intl'




export default function Footer(){

    return(
        <footer className=" laptop:flex md:items-center laptop:justify-between laptop:p-6 bg-zinc-800 text-white">
            <div className="Superdesktop:text-5xl text-l  text-white dark:text-gray-400 w-auto  pl-9 text-left text-2xl font-Taviraji">

            <img 
        className="w-52 Superdesktop:w-[28rem]"
        src={logo2}
       
        alt='GoFuel'
        />
        Going The Extra Mile

        <br/>
        <br/>
        <br/>
        <div className="flex">
        <div className=" Superdesktop:text-4xl  text-xl ml-0 text-gray-900 ">
        <a href="http://gofuelmz.com/gofuel/paginainicial/"
        
        
        >
        PORTUGUÊS
        </a>
            
           
            
        </div>

        <div className="Superdesktop:text-4xl  text-xl ">
        <a href="http://gofuelmz.com/gofuel/paginainicial/En/"
        className="ml-8">
        ENGLISH
        </a>
      

            
        </div>
        </div>
       

            </div>

            <div className="Superdesktop:text-4xl Superdesktop:pt-6 text-l text-gree dark:text-gray-400 w-auto h-auto Superdesktop:pl-2 pl-9 ">
            <FormattedMessage
                              id={'questoes.titulo'}
                                />
<hr className="footer-seperator" />
<p></p>
<div className="text-l text-white pt-3 Superdesktop:text-3xl Superdesktop:pt-5">
<FormattedMessage
                              id={'questoes.contact1'}
                                /> <br></br> <FormattedMessage
                                id={'questoes.contact2'}
                                  />

<br/>
<br/>
Tel. +258 21 48 75 32<br/>
Fax. +258 21 48 75 33<br/>
Email: info@gofuel-mz.com

</div>
      

</div>

<div className="Superdesktop:text-4xl Superdesktop:pt-6 Superdesktop:pl-1 text-l text-gree dark:text-gray-400 w-auto h-auto pl-9 ">
NEWSLETTER
<hr className="footer-seperator " />
<p></p>
<div className="text-l  text-white pt-5 flex">
<input
                        type="tel"
                        placeholder='SUBSCREVER'
                        pattern="" required
                        name="tel"
                      
                        className=" mt-0 Superdesktop:text-3xl focus:ring-orange-500 block w-auto shadow-sm sm:text-sm border-gray-300 bg-black"
                      />
                      <a href="">
                        
                        <img
                
                        src={ns}
                        width={40}
                        className="Superdesktop:w-[5rem]"
                        />

                      </a>

</div>
</div>


        </footer>
    );


}