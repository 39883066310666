import React from "react";
import fleet3 from "../Imagens/in.png"

import {FormattedMessage} from "react-intl";


export default function Inicio(){
    return(
        <div className="w-auto mt-14 cell:h-auto h-screen bg-zinc-800 ">
     <div>
          <div className="flex relative overflow-hidden">
        {/* <div class="flex-none"></div> */}
        <div className="flex-auto justify-end w-full cell:mx-auto laptop:pr-10 desktop:pr-10 "> 
        <br/><br/>
        <br/><br/>
        <br/><br/>
                <main className="mt-5  cell:-mt-32 laptop:mt-16 Superdesktop:mt-20 desktop:mt-0 desktop:px-8 xl:mt-28">
      <div className=" text-left ">
        <div className=''>
        <span className="Superdesktop:text-[152px]  text-6xl tracking-tight font-extrabold  cell:text-3xl laptop:text-6xl block text-gray-100 desktop:text-7xl text-7xl desktop:inline cell:pl-2"> <FormattedMessage defaultMessage="Faça a gestão da sua frota" id="inicio.titulo1"/></span>
        <span className="Superdesktop:text-5xl desktop:text-2xl  tablet:text-xl tracking-tight font-extrabold text-orange-400 cell:text-sm laptop:text-6xl block cell:-mt-5 desktop:inline cell:pl-2"> <br/><FormattedMessage id="inicio.titulo2"/></span>


</div>
        <h1 className="text-6xl tracking-tight font-extrabold text-gray-900 cell:text-3xl laptop:text-6xl">
          <span className="block laptop:inline">    

</span>{' '}
          <span className="block text-orange-400 xl:inline">
</span>
        </h1>
        <p className="mt-3 text-base text-gray-500 cell:mt-5 cell:text-lg cell:max-w-xl cell:mx-auto laptop:mt-5 laptop:text-xl desktop:mx-0 text-8xl">

        </p>
        <br></br>
  


        <div className="mt-5 telefone:mt-8 telefone:flex telefone:justify-center desktop:justify-start">
          {/* <div className="rounded-md shadow"> */}
         {/* 
         
         <MobileStoreButton
                  store="android"
                  url={androidUrl}
                  linkProps={{ title: 'iOS Store Button' }}
                />
         
         */


         } 

{/* <a href="https://google.com" target="_blank" rel="noreferrer">
          <GooglePlayStoreIcon />
        </a>
        <a href="https://google.com" target="_blank" rel="noreferrer">
          <AppStoreIcon />
        </a> */}
          <div className="mt-3 telefone:mt-0 telefone:ml-3 py-3">

            {/*
            <MobileStoreButton
                  store="ios"
                  url={iOSUrl}
                  linkProps={{ title: 'iOS Store Button' }}
                  width={180}
                />
            
            */}
            
          </div>
        </div>
      </div>
    </main>
        </div>
    <div className="flex-auto cell:w-96 w-8/9 Superdesktop:w-full desktop:w-full overflow-hidden pr-3">
  
    {/* <Image
    className="h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full"
    src="/images/gofuel_screenshot.png"
    alt="" 
    //width={900} height={500}
    width={300} height={550}
  /> */}
  <img
width={1250}

src={fleet3}
/>
</div>



        </div>


    </div>
      
        </div>
    );
} 