import React from "react";
import { FormattedMessage } from "react-intl";


export default function QuemSomos(){
    return(
        <div>
            <div className="text-center font-extrabold Superdesktop:text-7xl desktop:text-4xl laptop:text-4xl text-4xl p-10 cell:text-xl cell:p-5">
               <FormattedMessage
               id="quemsomos.titulo1"
               defaultMessage="  Quem Somos "/>
              
            </div>
            <p className="desktop:text-2xl desktop:p-10  cell:text-xl cell:p-3 Superdesktop:text-4xl Superdesktop:p-10   text-4xl p-10">
            <FormattedMessage
               id="quemsomos.titulo2"
             />


            <br/><br/>

            <FormattedMessage
               id="quemsomos.titulo3"
             />
             <br/>

            <FormattedMessage
               id="quemsomos.titulo31"
             />

            <br/>

            <FormattedMessage
               id="quemsomos.titulo32"
            />

            <br/>

            <FormattedMessage
               id="quemsomos.titulo33"
            />

            <br/>

            <FormattedMessage
               id="quemsomos.titulo34"
            />
            <br/><br/>
            <FormattedMessage
               id="quemsomos.titulo4"
              />
          
            </p>
            <br/>
            <br/>
        </div>
    );
}