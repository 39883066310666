import React, { useState, useEffect } from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import { faPhone} from "@fortawesome/free-solid-svg-icons"
import { faEnvelope} from "@fortawesome/free-solid-svg-icons"
import { FormattedMessage } from 'react-intl'



function Questoes(){

return(
  <div id='s'>
    <div id="co">
                    <div className="text-center "  >

<p className="mt-2 text-3xl Superdesktop:text-5xl leading-8 font-extrabold tracking-tight text-gray-900 ">
<FormattedMessage
                              id={'questoes.titulo'}
                                />
</p>
<p className="mt-4 max-w-2xl text-xl text-gray-500 tablet:mx-auto">

</p>
</div>
    <div className="bg-gray-200 h-auto laptop:flex font-Taviraji Superdesktop:m-10 desktop:m-10 laptop:m-10">
      
        
    
    <div className='bg-orange-400 w-auto Superdesktop:w-96  h-auto text-white Superdesktop:w-auto p-10 '>
        <div className='Superdesktop:text-5xl text-2xl text-center Superdesktop:p-5'>
        <FormattedMessage
                              id={'questoes.texto1'}
                                />
        </div>
    
       <div className='text-l Superdesktop:text-3xl Superdesktop:py-2 py-0.5'>
       <FormattedMessage
                              id={'questoes.texto2'}
                                /><br></br>
        
        <FormattedMessage
                              id={'questoes.texto3'}
                                /><br></br>
          <FormattedMessage
                              id={'questoes.texto4'}
                                /><br></br>
          <FormattedMessage
                              id={'questoes.texto5'}
                                />
         <br/>
         <FormattedMessage
                              id={'questoes.texto6'}
                                />
       </div>
    
       <br></br>  <br></br>
       <div className="flex p-2 text-l Superdesktop:p-3 Superdesktop:text-3xl">
    
       
       <FontAwesomeIcon icon={faPhone} />
                   &emsp;&emsp;         (+258) 21 48 75 32 
    
                  
    
              
    
       </div>
    
        <div className="md:flex p-2 Superdesktop:p-3 Superdesktop:text-3xl">
        <FontAwesomeIcon icon={faEnvelope} />
                 &emsp;&emsp; info@gofuel-mz.com
    
        </div>
    
        <div className='text-l py-0.5 Superdesktop:text-3xl Superdesktop:py-10 '>
        <FormattedMessage
                              id={'questoes.contact1'}
                                /> <br></br> <FormattedMessage
                                id={'questoes.contact2'}
                                  />
    
        <br></br>
        <br></br>
     
    
    
        </div>
       
    </div>
    
    <div className='bg-white superdesktop:w-full cell:w-full w-full h-auto Sp-5 Superdesktop:m-5 desktop:m-5 laptop:m-5 tablet:m-5  text-l flex'>
    
  
       

    

        <div className=" ">
          
          <div className="">
            <form action="#" method="POST">
              <div className="">
                <div className="Superdesktop:px-7 px-4 py-5 bg-white sm:p-6">
                  <div className="desktop:grid desktop:grid-rows-3 gap-6 flex">
                    <div className="col-span-3">
                      <label htmlFor="first-name" className="block Superdesktop:text-3xl text-sm font-medium text-gray-700">
                      <FormattedMessage
                              id={'questoes.label1'}
                                />
                      </label>
                      <input
                        type="text"
                        name="nomecompleto"
                        id="nomecompleto"
                        autoComplete="given-name"
                        placeholder='Nome Completo'
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-auto  Superdesktop:py-4 Superdesktop:text-3xl shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                 
                    </div>

                    <div className="col-span-6 sm:col-span-3">
                      <label htmlFor="email" className="block Superdesktop:text-3xl text-sm font-medium text-gray-700">
                      <FormattedMessage
                              id={'questoes.label2'}
                                />
                      </label>
                      <input
                        type="email"
                        name="email"
                        id="email"
                       placeholder='example@email.com'
                        className="mt-1 focus:ring-indigo-500 Superdesktop:text-3xl Superdesktop:py-4 focus:border-indigo-500 block w-auto shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-4">
                      <label htmlFor="tel" className="block Superdesktop:text-3xl text-sm font-medium text-gray-700">
                      <FormattedMessage
                              id={'questoes.label3'}
                                />
                      </label>
                      <input
                        type="tel"
                        placeholder='123456789'
                        pattern="[0-9]{9}" required
                        name="tel"
                      
                        className="mt-1 focus:ring-indigo-500 Superdesktop:text-3xl Superdesktop:py-4 focus:border-indigo-500 block w-auto shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>
                    <div className="col-span-19 sm:col-span-5">
                    <label htmlFor="tel" className="block Superdesktop:text-3xl text-sm font-medium text-gray-700">
                    <FormattedMessage
                              id={'questoes.label4'}
                                />
                      </label>
  <textarea className="Superdesktop:text-3xl Superdesktop:py-8 Superdesktop:px-8 form-textarea mt-1 block w-64 h-36 Superdesktop:w-[48rem] Superdesktop:h-[24rem]"  placeholder="Escreva a mensagem"></textarea>
           </div>
   

                    
                    

                                     
                  </div>
                </div>
                <div className="px-4 py-3 text-left sm:px-6">
                  <button
                    type="submit"
                    className="Superdesktop:py-6 Superdesktop:text-2xl  Superdesktop:px-[4rem] py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-orange-400 hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500"
                  >
                      <FormattedMessage
                              id={'questoes.label5'}
                                />
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

  

      


    
    </div>
    </div>
    </div>
);


}
export default Questoes