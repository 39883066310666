
import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

import { Autoplay, Pagination, Navigation } from "swiper";
import { FormattedMessage } from 'react-intl'

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import img1 from '../partners/autogas.png'
import img2 from '../partners/Petromoc.jpg';
import img3 from '../partners/CamelOil.jpg';
import img4 from '../partners/puma.png';
import img5 from '../partners/totalenergies.png';
import img6 from '../partners/engen_logo.png';
import img7 from '../partners/goi2a.png';
import img8 from '../partners/petromoc-sasol.jpg';



function Swipp(){


	return(

        <div>
   
   <div className="text-center">

<p className="mt-2 cell:text-2xl text-3xl leading-8 font-extrabold tracking-tight text-gray-900 desktop:text-3xl tablet:text-4xl Superdesktop:text-5xl">
<FormattedMessage
                              id={'Swipp.titulo'}
                                />

</p>

</div>


<Swiper
       
        spaceBetween={0}
        slidesPerView={3.5}
        centeredSlides={true}
        loop={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
      
        modules={[Autoplay]}
        className="mySwiper hidden tablet:block  "
      >
          <SwiperSlide>        <img
              className="mx-auto cell:w-auto Superdesktop:h-28 h-20 w-auto"
              src={img1}
              alt="Workflow"
            /></SwiperSlide>
       
        <SwiperSlide>        <img
              className="mx-auto Superdesktop:h-28 h-20 w-auto"
              src={img2}
              alt="Workflow"
            /></SwiperSlide>
        <SwiperSlide>        <img
              className="mx-auto Superdesktop:h-28 h-20 w-auto"
              src={img3}
              alt="Workflow"
            /></SwiperSlide>
        <SwiperSlide>        <img
              className="mx-auto Superdesktop:h-28 h-20 w-auto"
              src={img4}
              alt="Workflow"
            /></SwiperSlide>
        <SwiperSlide>        <img
              className="mx-auto Superdesktop:h-28 h-20 w-auto"
              src={img5}
              alt="Workflow"
            /></SwiperSlide>

<SwiperSlide>        <img
              className="mx-auto Superdesktop:h-28 h-20 w-auto"
              src={img6}
              alt="Workflow"
            /></SwiperSlide>

<SwiperSlide>        <img
              className="mx-auto Superdesktop:h-28 h-20 w-auto"
              src={img7}
              alt="Workflow"
            /></SwiperSlide>

<SwiperSlide>        <img
              className="mx-auto Superdesktop:h-28 h-20 w-auto"
              src={img8}
              alt="Workflow"
            /></SwiperSlide>
     
      </Swiper>

      
<Swiper
       
       spaceBetween={0}
       slidesPerView={1}
       centeredSlides={true}
       loop={true}
       autoplay={{
         delay: 2500,
         disableOnInteraction: false,
       }}
       pagination={{
         clickable: true,
       }}
     
       modules={[Autoplay]}
       className="mySwiper desktop:hidden laptop:hidden Superdesktop:hidden "
     >
         <SwiperSlide>        <img
             className="mx-auto cell:w-auto  h-20 w-auto"
             src={img1}
             alt="Workflow"
           /></SwiperSlide>
      
       <SwiperSlide>        <img
             className="mx-auto h-20 w-auto"
             src={img2}
             alt="Workflow"
           /></SwiperSlide>
       <SwiperSlide>        <img
             className="mx-auto h-20 w-auto"
             src={img3}
             alt="Workflow"
           /></SwiperSlide>
       <SwiperSlide>        <img
             className="mx-auto h-20 w-auto"
             src={img4}
             alt="Workflow"
           /></SwiperSlide>
       <SwiperSlide>        <img
             className="mx-auto h-20 w-auto"
             src={img5}
             alt="Workflow"
           /></SwiperSlide>

<SwiperSlide>        <img
             className="mx-auto h-20 w-auto"
             src={img6}
             alt="Workflow"
           /></SwiperSlide>

<SwiperSlide>        <img
             className="mx-auto h-20 w-auto"
             src={img7}
             alt="Workflow"
           /></SwiperSlide>

<SwiperSlide>        <img
             className="mx-auto h-20 w-auto"
             src={img8}
             alt="Workflow"
           /></SwiperSlide>
    
     </Swiper>
        </div>
		

	);


}

export default Swipp