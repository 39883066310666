import React from "react";
import flet_app from "../Imagens/flet_app.png"
import all_app from "../Imagens/card2_app.png"
import app_store from "../Imagens/applestore.png"
import android from "../Imagens/android.png"
import huawei from "../Imagens/huawei.png"
import fleet from "../Imagens/fleet.png"
import cardD from "../Imagens/card.png"
import { Card } from "@mui/material";
import { FormattedMessage } from 'react-intl'





export default function Download(){

    return(
        <div  >



 





        <div className="text-center">

        <p className="text-3xl Superdesktop:text-6xl mt-2 cell:text-2xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            Download
        </p>
        <p className="mt-4 Superdesktop:text-3xl max-w-2xl text-xl cell:text-sm text-gray-500 laptop:mx-auto text-center">
        <FormattedMessage
          id={'download.subtitulo'}
          />
        </p>

        </div>
        <div className=" laptop:grid-rows-2 laptop:flex  ">

<div>
    <div className="">
    <div className="absolute Superdesktop:pt-[9%] Superdesktop:pl-[20%] desktop:pl-[15%] desktop:pt-32 desktop:w-auto laptop:w-auto  laptop:pl-72 laptop:pt-48 tablet:pt-48 tablet:pl-48 tablet:w-64 cell:w-24 cell:mt-28 cell:ml-48  ">
    <a href='https://apps.apple.com/ai/app/gofuel-fleet/id1603608240'> <img 
        src={app_store}
        width={130}
        height={50}
        className="Superdesktop:w-[13%]"
        alt='GoFuel'
        /></a>
        <br></br>
          <a href='https://play.google.com/store/apps/details?id=com.waltersitoe.gofuel_fleet&hl=en_US&gl=US'> <img 
        src={android}
        width={130}
        height={50}
        className="Superdesktop:w-[13%]"
        alt='GoFuel'
        /></a>
         <br></br>
          <a href='https://appgallery.huawei.com/app/C106660299'> <img 
        src={huawei}
        width={130}
        height={50}
        className="Superdesktop:w-[13%]"
        alt='GoFuel'
        /></a>

    </div>
    <img
src={fleet}
width={1200}


/>

    </div>
 


</div>


<div className="">
<div className="absolute Superdesktop:pt-[13%] Superdesktop:pl-[20%] desktop:pl-[15%] desktop:pt-60 desktop:w-auto laptop:w-auto  laptop:pl-72 laptop:pt-48 tablet:pt-48 tablet:pl-48 tablet:w-64 cell:w-24 cell:pt-28 cell:ml-48">
    <a href='https://apps.apple.com/ai/app/gofuel-tracking-system/id1530324152'> <img 
        src={app_store}
        width={130}
        height={50}
        className="Superdesktop:w-[37%]"
        alt='GoFuel'
        /></a>
        <br></br>
          <a href='https://play.google.com/store/apps/details?id=com.gofuel_ios&hl=en_US&gl=US&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'> <img 
        src={android}
        width={130}
        height={50}
        className="Superdesktop:w-[37%]"
        alt='GoFuel'
        /></a>
         <br></br>
          <a href='https://play.google.com/store/apps/details?id=com.gofuel_ios&hl=en_US&gl=US&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'> <img 
        src={huawei}
        width={130}
        height={50}
        className="Superdesktop:w-[37%]"
        alt='GoFuel'
        /></a>

    </div>
<img
src={cardD}
width={1200}
/>
</div>


</div>
        


        </div>

);



}